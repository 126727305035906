import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import MainLayout from "./layouts/mainLayout/mainLayout";
import Home from "./pages/home/home";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
