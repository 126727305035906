import "./action-button.scss";

const ActionButton = ({ content = "Explore Phase Z launches", link = "/", target = "_blank" }) => {
  return (
    <div className="action-button-container">
      <a href={link} target={target} className="action-button">
        {content}
      </a>
    </div>
  );
};

export default ActionButton;
