import "./external-cta.scss";
import caretRight from "./../../assets/icons/caret-right.svg";

const ExternalCta = ({ content = "", link = "/", color = "default", target="_blank" }) => {
  return (
    <div className={`external-cta-container color-${color}`}>
      <a href={link} target={target} className="external-cta">
        {content}
        <img src={caretRight} alt="Zenon" />
      </a>
    </div>
  );
};

export default ExternalCta;
