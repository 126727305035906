import Header from "../../components/header/header";
import "./home.scss";
import heroBg from "./../../assets/img/hero-bg.png";
import zenonNetworkText from "./../../assets/img/zenon-network.svg";
import caretDown from "./../../assets/icons/caret-down.svg";
import pillarsAllianceStamp from "./../../assets/img/pillars-alliance-stamp.png";
import pillarImage from "./../../assets/img/pillar.png";
import phaseZLogoComplete from "./../../assets/img/phaseZ-logo-complete.png";
import stage2Img from "./../../assets/img/stage-2.png";
import stage3Img from "./../../assets/img/stage-3.png";
import stage4Img from "./../../assets/img/stage-4.png";
import stage5Img from "./../../assets/img/stage-5.png";
import ActionButton from "../../components/action-button/action-button";
import participantIcon1 from "./../../assets/ecosystem-icons/1.svg";
import participantIcon2 from "./../../assets/ecosystem-icons/2.svg";
import participantIcon3 from "./../../assets/ecosystem-icons/3.svg";
import participantIcon4 from "./../../assets/ecosystem-icons/4.svg";
import participantIcon5 from "./../../assets/ecosystem-icons/5.svg";
import participantIcon6 from "./../../assets/ecosystem-icons/6.svg";
import participantIcon7 from "./../../assets/ecosystem-icons/7.svg";
import participantIcon8 from "./../../assets/ecosystem-icons/8.svg";
import ExternalCta from "../../components/external-cta/external-cta";
import Footer from "../../components/footer/footer";

const Home = () => {
  return (
    <div className="home-body">
      <Header />

      <main className="main">
        <section className="hero">
          <img className="hero-bg" src={heroBg} alt="Phase Z" />

          <div className="hero-content big-container">
            <div className="text-cta">
              <img src={zenonNetworkText} className="hero-headline" alt="Zenon Network"></img>
              <div className="hero-subheadline">
                Waking up the <br></br>sleeping giant
              </div>
              <ActionButton link="#explore" target="_self" />
            </div>
            <div className="hero-info">
              <div className="info-images">
                <img className="" src={pillarsAllianceStamp} alt="Phase Z" />
                <div className="ml-4 mr-0" style={{ fontSize: "35px", fontWeight: "800" }}>
                  X
                </div>
                <img className="" src={phaseZLogoComplete} alt="Phase Z" />
              </div>
              <div className="hero-info__text">Campaign supported by the Zenon Pillars Alliance</div>
            </div>
          </div>
        </section>

        <section className="financial-section">
          <div className="financial-section__box">
            <div className="financial-section__text">Marketcap</div>
            <div className="financial-section__text">ATH</div>
            <div className="financial-section__main-number">~$700M</div>
          </div>
          <div className="financial-section__box">
            <div className="financial-section__text">Marketcap</div>
            <div className="financial-section__text">NOW</div>
            <div className="financial-section__main-number">~$10M</div>
          </div>
        </section>

        <div className="big-container">
          <section id="explore" className="explore-phases-title mt-5 mb-5">
            <img className="" src={caretDown} alt="Phase Z" />
            Explore Phase Z launches
            <img className="" src={caretDown} alt="Phase Z" />
          </section>
          <div className="stage-container">
            <div className="stage-title">STAGE 1</div>
            <div className="stage-parts mt-3">
              <div className="stage-part left-side">
                <img className="stage-part__bg-image" src={pillarImage} alt="Zenon Pillar Alliance"></img>
                <div className="stage-part__title">ZENON PILLARS ALLIANCE</div>
                <div className="stage-part__content mt-2">
                  <span className="color-primary mr-1">#PhaseZ</span>
                  is our campaign to announce, promote, and support Zenon’s groundbreaking advancements. Zenon Pillar
                  Alliance is supported by community members with true Alien blood.
                </div>
              </div>

              <div className="stage-part right-side">
                <div className="stage-part__title">
                  AMBASSADOR PROGRAM
                  <span className="live-badge ml-2">LIVE</span>
                </div>
                <div className="stage-part__content mt-2">
                  <div>
                    <b>Are you a crypto superstar on X, or a pro Telegram?</b>
                  </div>
                  <div className="mt-2">
                    We’re calling the KOLs to join our alien space force
                    <a href="https://x.com/zenonalliance" target="_blank"><b className="color-primary ml-1">@ZenonAlliance</b></a>
                  </div>
                  <div className="mt-2 highlighted-text">
                    <b>Only the most viral posts will be rewarded with $ZNN and $QSR</b>
                  </div>
                  <div className="mt-2 stage-part__button">
                    <ActionButton content="Join Now" link="https://x.com/zenonalliance/status/1803811013566869933"></ActionButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="stage-container mt-3">
            <div className="stage">
              <div className="left-side">
                <div className="stage-title">STAGE 2</div>
                <div className="stage__subtitle">Syrius Telegram Wallet and Mini-Apps: Incentivized program </div>
                <div className="stage__content">
                  Incentivized program through Syrius Wallet for Telegram and native mini apps for Telegram’s 1.5
                  billion users
                </div>
              </div>
              <div className="right-side">
                <img className="" src={stage2Img} alt="Stage 2" />
              </div>
            </div>
          </div>

          <div className="stage-container mt-3">
            <div className="stage">
              <div className="left-side">
                <div className="stage-title">STAGE 3</div>
                <div className="stage__subtitle">Supernova release: Extension Chains </div>
                <div className="stage__content">
                  Unlimited horizontal scalability and use-cases.
                  <br></br>
                  Deploy an extension-chain on Zenon.
                </div>
                <div className="stage__selling-points-container">
                  <div className="stage__selling-points">
                    <div className="stage__selling-point purple-point">Scalability</div>
                    <div className="stage__selling-point purple-point">Shared-security</div>
                    <div className="stage__selling-point purple-point">Programmability</div>
                  </div>
                  <div className="stage__selling-points">
                    <div className="stage__selling-point green-point">Open-source</div>
                    <div className="stage__selling-point green-point">Hardware security</div>
                    <div className="stage__selling-point green-point">Fast</div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <img className="" src={stage3Img} alt="Stage 3" />
              </div>
            </div>
          </div>

          <div className="stage-container mt-3">
            <div className="stage">
              <div className="left-side">
                <div className="stage-title">STAGE 4</div>
                <div className="stage__subtitle">Syrius Mobile Wallet </div>
                <div className="stage__content">The power of Zenon in your pocket</div>
                <div className="stage__selling-points">
                  <div className="stage__selling-point green-point">Open-source</div>
                  <div className="stage__selling-point green-point">Hardware Security</div>
                  <div className="stage__selling-point green-point">Fast</div>
                  <div className="stage__selling-point green-point">No sign-up</div>
                  <div className="stage__selling-point green-point">Self-custody</div>
                  <div className="stage__selling-point green-point">Private</div>
                </div>
              </div>
              <div className="right-side stage-4-image">
                <img src={stage4Img} alt="Stage 4" />
              </div>
            </div>
          </div>

          <div className="stage-container mt-3">
            <div className="stage">
              <div className="left-side">
                <div className="stage-title">STAGE 5</div>
                <div className="stage__subtitle">Bitcoin Merge Mining</div>
                <div className="stage__content">
                  Start mining BTC on Zenon and get ZNN rewards. Mined BTC to a protocol-level treasury that will be
                  used for ecosystem expansion:{" "}
                </div>
                <div className="stage__selling-points">
                  <div className="stage__selling-point brown-point">ZNN backed by BTC</div>
                  <div className="stage__selling-point brown-point">Bitcoin blockspace acquisition</div>
                  <div className="stage__selling-point brown-point">Accelerator Z funding also in BTC</div>
                </div>
              </div>
              <div className="right-side">
                <img className="" src={stage5Img} alt="Stage 5" />
              </div>
            </div>
          </div>

          <div className="explore-participants-title mt-5 mb-5">
            Zenon Network is the decentralized ecosystem of the future that incentivize every type of participant
          </div>

          <div className="participants-container">
            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon1} alt="Pillar nodes" />
              </div>
              <div className="participant-item__title">Pillar nodes</div>
              <div className="participant-item__description">Operate a consensus node on NoM</div>
              <ExternalCta content="Spawn Pillar" link="https://zenon.org/en/roles/pillar" />
            </div>

            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon2} alt="Sentinel Nodes" />
              </div>
              <div className="participant-item__title">Sentinel Nodes</div>
              <div className="participant-item__description">Operate an observer node on NoM</div>
              <ExternalCta content="Stake Now" link="https://zenon.org/en/roles/sentinel" />
            </div>

            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon3} alt="Stakers" />
              </div>
              <div className="participant-item__title">Stakers</div>
              <div className="participant-item__description">Stake ZNN in exchange for QSR rewards</div>
              <ExternalCta content="Stake Now" link="https://zenon.org/en/roles/staker" />
            </div>

            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon4} alt="Builders" />
              </div>
              <div className="participant-item__title">Builders</div>
              <div className="participant-item__description">Access Accelerator-Z funding for your Web3 project</div>
              <ExternalCta content="Apply Now" link="https://zenon.org/en/funding" />
            </div>

            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon5} alt="Delegators" />
              </div>
              <div className="participant-item__title">Delegators</div>
              <div className="participant-item__description">Delegate ZNN to a Pillar and earn ZNN rewards</div>
              <ExternalCta content="Delegate Now" link="https://zenon.org/en/roles/delegator" />
            </div>

            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon6} alt="Liquidity Provider" />
              </div>
              <div className="participant-item__title">
                Liquidity Provider
                <img height="60px" src={phaseZLogoComplete} alt="PhaseZ" />
              </div>
              <div className="participant-item__description">Stake your liquidity for ZNN & QSR rewards</div>
              <ExternalCta content="LP Now" link="https://zenon.org/en/roles/liquidity-provider" />
            </div>

            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon7} alt="Miners" />
                <span className="soon-badge">SOON</span>
              </div>
              <div className="participant-item__title">
                Miners
                <img height="60px" src={phaseZLogoComplete} alt="PhaseZ" />
              </div>
              <div className="participant-item__description">Mine BTC for ZNN rewards</div>
              <ExternalCta content="Stake Now (soon)" link="#" />
            </div>

            <div className="participant-item">
              <div className="participant-item__icon">
                <img src={participantIcon8} alt="Farmers" />
                <span className="soon-badge">SOON</span>
              </div>
              <div className="participant-item__title">
                Farmers
                <img height="60px" src={phaseZLogoComplete} alt="PhaseZ" />
              </div>
              <div className="participant-item__description">Operate a consensus node on NoM</div>
              <ExternalCta content="Farm on Syrius Telegram Wallet and Mini-apps (soon)" link="#" />
            </div>
          </div>
        </div>
      </main>

      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
