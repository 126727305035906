import "./header.scss";
import znnLogo from "./../../assets/znn-logo.svg";
import NavCta from "../nav-cta/nav-cta";

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar">
        <div className="header-section logo-section">
          <a href="/" className="primary">
            <img src={znnLogo} alt="Zenon" />
          </a>
        </div>
        <div className="header-section">
          <NavCta link="https://zenon.network/#exchange" />
        </div>
      </nav>
    </header>
  );
};

export default Header;
