import "./footer.scss";
import phaseZLogo from "./../../assets/img/phaseZ-logo-complete.png";
import socialIcon1 from "./../../assets/social-icons/1.svg";
import socialIcon2 from "./../../assets/social-icons/2.svg";
import socialIcon3 from "./../../assets/social-icons/3.svg";
import socialIcon4 from "./../../assets/social-icons/4.svg";
import socialIcon5 from "./../../assets/social-icons/5.png";
import ExternalCta from "../external-cta/external-cta";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer big-container">
        <div className="footer-section footer__left-section">
          <a href="/" className="primary">
            <img height="150px" src={phaseZLogo} alt="Zenon" />
          </a>
          <div className="footer__text">
            Standard for decentralization
            <br></br>
            Unlimited horizontal scalability and use-cases.
          </div>
        </div>
        <div className="footer-section">
          <div className="footer__social">
            <div>
              <a href="https://x.com/zenonalliance" className="footer__social-icon" target="_blank" rel="noopener nofollow noreferrer">
                <img src={socialIcon1} alt="social-platform" />
              </a>
            </div>
            <div>
              <a href="https://www.coingecko.com/en/coins/zenon" className="footer__social-icon" target="_blank" rel="noopener nofollow noreferrer">
                <img src={socialIcon2} alt="social-platform" />
              </a>
            </div>
            <div>
              <a href="https://x.com/zenonalliance/status/1803811013566869933" className="footer__social-icon" target="_blank" rel="noopener nofollow noreferrer">
                <img src={socialIcon3} alt="social-platform" />
              </a>
            </div>
            <div>
              <a href="https://cointelegraph.com/news/a-dual-ledger-layer-one-plans-to-supersede-ethereum-and-solana-by-solving-the-blockchain-trilemma-with-a-feeless-paradigm" className="footer__social-icon" target="_blank" rel="noopener nofollow noreferrer">
                <img src={socialIcon4} alt="social-platform" />
              </a>
            </div>
            <div>
              <a href="https://zenon.tools" className="footer__social-icon" target="_blank" rel="noopener nofollow noreferrer">
                <img src={socialIcon5} alt="social-platform" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-section footer__right-section">
          <div className="footer-menu">
          </div>
          <div style={{ width: "150px" }}>
            <ExternalCta content="Resources" color="primary" link="https://zenon.org/en/library" />
          </div>
        </div>
      </div>
      <div className="big-container mt-3">
        <div className="footer-divider-line"></div>
        <div className="footer-legal-section mt-3">
          <div className="left-side">
          </div>
          <div className="right-side">@2024 Zenon Network Alliance<br />This website is not affiliated with Zenon Network. Not financial advice</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
