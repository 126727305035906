import { Outlet } from "react-router-dom";
import "./mainLayout.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {
  return (
    <div className="main-layout">
      <Outlet />
      <ToastContainer />

      <div id="spinner-root"></div>
    </div>
  );
};

export default MainLayout;
