import "./nav-cta.scss";
import znnLogo from "./../../assets/znn-logo.svg";

const NavCta = ({link = "/", target="_blank"}) => {
  return (
    <div className="nav-cta-container">
      <a href={link} target={target} className="nav-cta">
        Buy
        <img src={znnLogo} alt="Zenon" />
      </a>
    </div>
  );
};

export default NavCta;
